import React from 'react'
import datastore from '../datastore'
import { RichTextField } from './prismic-elements'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { useQuery } from '../utils/helpers'

import './SearchBar.sass'

// Déclaration du composant
const SearchBar = ({ className, defaultValueJobs, defaultValueSectors, defaultValueLocations, showCTA = true }) => {
  const search_bar = datastore.layoutDoc.data.search_bar[0]
  // Récupération des paramètres tels que la langue, historique et requêtes
  const { pathname } = useLocation()
  const { lang } = useParams()
  const query = useQuery()
  const history = useHistory()

  if (query.get('job')) defaultValueJobs = query.get('job')
  if (query.get('sector')) defaultValueSectors = query.get('sector')
  if (query.get('location')) defaultValueLocations = query.get('location')

  // Setting des variables utilisables dans le state via useState. Chacune de ces variable est associé à son setter
  const [job, setJob] = React.useState(defaultValueJobs)
  const [sector, setSector] = React.useState(defaultValueSectors)
  const [location, setLocation] = React.useState(defaultValueLocations)

  // Déclaration de la fonction de recherche
  const doSearch = (useQuery) => {
    let url = (lang ? '/' + lang : '') + '/applicants/jobs'

    if (useQuery) {
      // if (query.get('job') === '') query.delete('job')
      // if (query.get('sector') === '') query.delete('sector')
      // if (query.get('location') === '') query.delete('location')

      if (job) {
        query.set('job', job)
      } else {
        query.delete('job')
      }
      if ((!job || job === 'engineer') && sector) {
        query.set('sector', sector)
      } else {
        query.delete('sector')
      }
      if (location) {
        query.set('location', location)
      } else {
        query.delete('location')
      }

      url += '?' + query.toString()
    }

    history.push(url)
  }

  // HTML Composant
  return (
    <div key={`${pathname}-${query.toString()}`} className={`search-bar-filter ${(className || '')}`}>
      <div className='search-bar-filter-form w-form'>
        <form className='search-bar-filter-form-normal'>
          <select
            className='search-bar-filter-dropdown w-select'
            aria-label={search_bar.option_job_label}
            defaultValue={defaultValueJobs}
            onChange={(e) => {
              // query.set('job', e.target.value)
              setJob(e.target.value)
            }}
          >
            <option value=''>{search_bar.option_job_label}</option>
            {
              datastore.jobs.map((item, index) => (
                <option key={index} value={item.uid}>{item.data.name}</option>
              ))
            }
          </select>
          {
            // Workaround ask by client 22/12/2020
            (!job || job === 'engineer') &&
              <select
                className='search-bar-filter-dropdown w-select'
                aria-label={search_bar.option_sector_label}
                defaultValue={defaultValueSectors}
                onChange={(e) => {
                  // query.set('sector', e.target.value)
                  setSector(e.target.value)
                }}
              >
                <option value=''>{search_bar.option_sector_label}</option>
                {
                  datastore.sectors.map((item, index) => (
                    <option key={index} value={item.uid}>{item.data.name}</option>
                  ))
                }
              </select>
          }
          <select
            className='search-bar-filter-dropdown w-select'
            aria-label={search_bar.option_location_label}
            defaultValue={defaultValueLocations}
            onChange={(e) => {
              // query.set('location', e.target.value)
              setLocation(e.target.value)
            }}
          >
            <option value=''>{search_bar.option_location_label}</option>
            {
              datastore.locations.map((item, index) => (
                <option key={index} value={item.uid}>{item.data.name}</option>
              ))
            }
          </select>
          <input
            onClick={(e) => {
              e.preventDefault()
              doSearch(true)
            }}
            type='submit'
            value=''
            className='button-icon button-icon-search is-circle-secondary w-button'
          />
        </form>
      </div>
      {
        showCTA &&
          <div
            className='search-bar-filter-cta'
            onClick={() => doSearch(true)}
          >
            <RichTextField field={search_bar.cta} />
          </div>
      }
    </div>
  )
}

// Export par défaut du composant
export default SearchBar
