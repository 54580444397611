import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { DataLink } from "../prismic-elements";
import {
  getLang,
  setUserLang,
  getCookie,
  getPathWithCurrentLang,
} from "../../utils/helpers";
import mcaLogo from "../../assets/images/mca-logo.svg";
import { Helmet } from "react-helmet";
import datastore from "../../datastore";

import "./Header.sass";

const Header = ({ layoutDoc, apiDoc }) => {
  const [isMenuOpened, setIsMenuOpened] = React.useState(false);
  const [subMenuMenuOpened, setSubMenuOpened] = React.useState(-1);

  const extraClassHeader = isMenuOpened ? " is-menu-opened" : "";

  const defaultLang = apiDoc.languages[0].id;
  const lang = getLang(window.location.pathname) || defaultLang;

  /* Google analytics variable*/
  let [resultGa] = datastore.ga.results;
  let gaId = resultGa?.data.ga[0].text;
  let cookieConsentStatus = getCookie('cookieconsent_status')

  if (layoutDoc) {
    return (
      <header className={"header" + extraClassHeader}>
        {(cookieConsentStatus === 'allow' || cookieConsentStatus === '') && (gaId ? (
          <Helmet>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`}
            ></script>
            <script>{`window.GA_MEASUREMENT_ID = '${gaId}';`}</script>
          </Helmet>
        ) : (
          <Helmet>
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=G-ZV9C0N6ZXD"
            ></script>
            <script>window.GA_MEASUREMENT_ID = 'G-ZV9C0N6ZXD';</script>
          </Helmet>
        ))}
        <div className="header-navbar-container w-container">
          <div className="navbar">
            <div className="navbar-burger">
              <div
                className="burger-toggle"
                onClick={() => setIsMenuOpened(!isMenuOpened)}
                data-checked={isMenuOpened}
              >
                <div className="line half start" />
                <div className="line" />
                <div className="line half end" />
              </div>
            </div>
            <RouterLink
              to={getPathWithCurrentLang(layoutDoc.currentLangPath, "/")}
              aria-current="page"
              className="navbar-brand w-inline-block w--current"
              onClick={() => setIsMenuOpened(false)}
            >
              <img
                src={mcaLogo}
                width="72"
                height="30"
                loading="lazy"
                alt="MCA Logo"
              />
            </RouterLink>
            <ul className="navbar-menu-list">
              {layoutDoc.data.navbar_menu_items.map((menuItem, index) => (
                <MenuItem
                  menuItem={menuItem}
                  key={"menu-item-" + index}
                  isSubMenuOpened={subMenuMenuOpened === index}
                  openMenu={() => setIsMenuOpened(true)}
                  closeMenu={() => setIsMenuOpened(false)}
                  toggleSubMenu={() =>
                    setSubMenuOpened(subMenuMenuOpened === index ? -1 : index)
                  }
                />
              ))}
            </ul>
            <div className="navbar-cta-list">
              {layoutDoc.data.navbar_cta_items.map((ctaItem, index) => (
                <DataLink
                  key={"cta-item-" + index}
                  prismicLink={ctaItem.link}
                  className="navbar-cta-list-item w-inline-block"
                  style={{ backgroundImage: `url(${ctaItem.image.url})` }}
                  title={ctaItem.label}
                >
                  <div className="navbar-cta-list-item-label">
                    {ctaItem.label}
                  </div>
                </DataLink>
              ))}
              <div className="navbar-lang">
                <select
                  key={"lang-selector-" + lang}
                  aria-label="Choose language"
                  defaultValue={lang}
                  onChange={(e) => {
                    const newLang =
                      e.target.value !== defaultLang
                        ? e.target.value
                        : "default";
                    setUserLang(newLang);
                    window.location.href =
                      window.location.origin +
                      "/" +
                      (newLang === "default" ? "en-us" : newLang);
                  }}
                >
                  {apiDoc.available_languages.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.id}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
  return null;
};

const MenuItem = ({
  menuItem,
  isSubMenuOpened,
  toggleSubMenu,
  openMenu,
  closeMenu,
}) => {
  const hasSubMenu =
    menuItem.items && menuItem.items.length > 0 && menuItem.items[0].label;

  return (
    <li
      onMouseEnter={openMenu}
      className={
        "navbar-menu-list-item" +
        (hasSubMenu ? " has-sub-menu" : "") +
        (isSubMenuOpened ? " is-sub-menu-opened" : "")
      }
    >
      <DataLink
        exact
        activeClassName="is-active"
        prismicLink={menuItem.primary.link}
        onClick={() => {
          hasSubMenu ? toggleSubMenu() : closeMenu();
        }}
        className="navbar-menu-list-item-label w-inline-block"
      >
        {menuItem.primary.label}
      </DataLink>
      {hasSubMenu && (
        <div className="navbar-sub-menu">
          <ul className="navbar-sub-menu-list">
            {menuItem.items.map((subItem, index) => {
              return (
                <li
                  className="navbar-sub-menu-list-item"
                  key={"subitem-" + index}
                >
                  <DataLink
                    exact
                    activeClassName="is-active"
                    prismicLink={subItem.link}
                    onClick={closeMenu}
                    className="navbar-sub-menu-list-item-link w-inline-block"
                  >
                    {subItem.label}
                  </DataLink>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </li>
  );
};

export default Header;
