import React from 'react'
import { Footer, Header } from './'
import { ScrollRestoration } from '..'
import { client, slicesFetchLinks } from '../../utils/prismicHelpers'
import { getLang, getCookie, getUserLang, setUserLang } from '../../utils/helpers'
import datastore from '../../datastore'
import Prismic from 'prismic-javascript'

let cookieConsentPopup

/**
 * Default site layout component
 */
const DefaultLayout = ({ children }) => {
  const [prismicData, setPrismicData] = React.useState({ apiDoc: null, layoutDoc: null, sectors: null, jobs: null, locations: null, subsidiaries: null, countries:null })
  const lang = getLang(window.location.pathname)

  // Get the global app data from Prismic
  // UseEffect => La suite d'action est lancée si une modification / update arrive sur les données écoutées

  React.useEffect(() => {
    const fetchPrismicData = async () => {
      try {
        const apiDoc = await client.api.get()
        const [layoutDoc, sectors, jobs, locations, subsidiaries, country, ga] = await Promise.all([
          client.getSingle('layout', { lang }),
          // Récupétation données des secteurs
          client.query(
            Prismic.Predicates.at('document.type', 'sector'),
            { lang, orderings: '[my.sector.name]' }
          ),
          // Récupétation données des jobs
          client.query(
            Prismic.Predicates.at('document.type', 'job'),
            { lang, orderings: '[my.job.name]' }
          ),
          // Récupétation données des locations
          client.query(
            Prismic.Predicates.at('document.type', 'location'),
            { lang, orderings: '[my.location.name]' }
          ),
          // Récupétation données des locaux subsidiaires
          client.query(
            Prismic.Predicates.at('document.type', 'subsidiary'),
            { lang, orderings: '[my.subsidiary.name]', fetchLinks: slicesFetchLinks }
          ),
          client.query(
            Prismic.Predicates.at('document.type', 'addcountriestomap'),
            { lang}
          ),
          client.query(
            Prismic.Predicates.at('document.type', 'googleanalytics'),
            { lang}
          ),
        ])

        if (layoutDoc) {
          const availableLanguages = [layoutDoc.lang]
          for (let index = 0; index < layoutDoc.alternate_languages.length; index++) {
            const al = layoutDoc.alternate_languages[index]
            availableLanguages.push(al.lang)
          }

          apiDoc.available_languages = apiDoc.languages.filter(_ => availableLanguages.indexOf(_.id) > -1)

          // Actually starting analytics up
          function addAnalytics () {
            window.dataLayer = window.dataLayer || []

            window.gtag = function gtag () {
              window.dataLayer.push(arguments)
            }

            window.gtag('js', new Date())
            window.gtag('config', window.GA_MEASUREMENT_ID)
          }

          const cookieConsent = layoutDoc.data.cookie_consent[0]

          // Reading "cookieconsent_status" cookie
          const cookieConsentStatus = getCookie('cookieconsent_status')

          // Start analytics if user consented or did not deny
          if (cookieConsentStatus === 'allow' || cookieConsentStatus === '') {
            addAnalytics()
          }

          if (cookieConsentPopup) {
            cookieConsentPopup.destroy()
            cookieConsentPopup = null
          }

          cookieConsent && window.cookieconsent.initialise({
            onInitialise: function (args) {
            },
            onPopupOpen: function (args) {
              cookieConsentPopup = this
            },
            onPopupClose: function (args) {
            },
            // Reload the page on user choice to make sure cookie is set
            onStatusChange: function (status, chosenBefore) {
              /*
              if (!getUserLang()) {
                setUserLang(lang)
              }
              */  
              setUserLang(lang)
              window.location.reload()
            },
            palette: {
              popup: {
                background: '#01103d'
              },
              button: {
                background: '#58ff8f',
                text: '#000000'
              }
            },
            theme: 'classic',
            type: 'opt-in',
            content: {
              message: cookieConsent.message,
              dismiss: cookieConsent.button_dismiss_label,
              deny: cookieConsent.button_deny_label,
              allow: cookieConsent.button_allow_label,
              link: cookieConsent.link_policy_label,
              href: cookieConsent.link_policy_url
            }
          })

          layoutDoc.currentLangPath = lang
          
          datastore.ga = ga
          datastore.apiDoc = apiDoc
          datastore.layoutDoc = layoutDoc
          datastore.sectors = sectors.results
          datastore.jobs = jobs.results
          datastore.locations = locations.results
          datastore.subsidiaries = subsidiaries.results
          datastore.countries = country.results

          setPrismicData({ apiDoc, layoutDoc, sectors: sectors.results, jobs: jobs.results, locations: locations.results, subsidiaries: subsidiaries.results, countries: country.results })
        } else {
          console.warn('Global Prismic data was not found. Make sure it exists in your Prismic repository.')
          window.location.href = '/404'
        }
      } catch (error) {
        console.error(error)
      }
    }

    // Ref param
    const query = new URLSearchParams(window.location.search)
    datastore.ref = query.get('ref') || 'Website'

    fetchPrismicData()
  }, [lang])

  if (!prismicData.layoutDoc) {
    return null
  }

  return (
    <>
      <ScrollRestoration appNodeId='root' />
      <Header layoutDoc={prismicData.layoutDoc} apiDoc={prismicData.apiDoc} />
      <main key={'main-' + prismicData.layoutDoc.lang}>
        {children}
      </main>
      <Footer layoutDoc={prismicData.layoutDoc} />
    </>
  )
}

export default DefaultLayout
